import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { OnboardingOptionsVO, PersonalDetailsVO } from "@libs/api/generated-api";
import { formatAsISODate, getDateIfValid } from "@libs/utils/date";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import { FormFieldUserSsnInput } from "@libs/components/UI/FormFieldUserSsnInput";
import { FormFieldDatePicker } from "components/UI/FormFieldDatePicker";
import { FormFieldSelectGender } from "components/UI/FormFieldSelectGender";
import { AccountValidationResult } from "components/Account/validation";
import { useHandleError } from "api/handleErrorResponse";

type Props = {
  showPreferredPronouns?: boolean;
  patientId: number;
  edit: boolean;
  onboardingOptions: OnboardingOptionsVO;
  personalDetails: Partial<PersonalDetailsVO>;
  referredBy?: string;
  onPersonalDetailsUpdated: (partial: Partial<PersonalDetailsVO>) => void;
  onReferredByUpdated?: (referredBy: string | undefined) => void;
  validation: AccountValidationResult["personalDetails"];
};

export const PersonalDetailFields: React.FC<Props> = ({
  onPersonalDetailsUpdated,
  onReferredByUpdated,
  showPreferredPronouns,
  personalDetails,
  patientId,
  onboardingOptions,
  referredBy,
  validation,
  edit,
}) => {
  const handleError = useHandleError();
  const { t } = useTranslation();
  const pronounOptions = React.useMemo(() => {
    return [
      { value: "HE_HIM" as const, label: t("He/Him") },
      { value: "SHE_HER" as const, label: t("She/Her") },
      { value: "THEY_THEM" as const, label: t("They/Them") },
    ];
  }, [t]);

  const referralOptions = useMemo(() => {
    return onboardingOptions.referralOptions.map((option) => ({ label: option, value: option }));
  }, [onboardingOptions.referralOptions]);

  return (
    <>
      <FormFieldInput
        id="first-name"
        required
        edit={edit}
        label={t("First Name")}
        placeholder={t("First Name")}
        onChange={(e) => {
          onPersonalDetailsUpdated({ firstName: e.target.value });
        }}
        value={personalDetails.firstName}
        error={validation.firstName.$error}
      />
      <FormFieldInput
        id="middle-name"
        edit={edit}
        label={t("Middle Name")}
        placeholder={t("Middle Name")}
        onChange={(e) => {
          onPersonalDetailsUpdated({ middleName: e.target.value });
        }}
        value={personalDetails.middleName}
      />
      <FormFieldInput
        id="last-name"
        edit={edit}
        required
        label={t("Last Name")}
        placeholder={t("Last Name")}
        onChange={(e) => {
          onPersonalDetailsUpdated({ lastName: e.target.value });
        }}
        value={personalDetails.lastName}
        error={validation.lastName.$error}
      />
      <FormFieldInput
        id="preferred-name"
        edit={edit}
        label={t("Preferred Name")}
        placeholder={t("Preferred Name")}
        onChange={(e) => {
          onPersonalDetailsUpdated({ preferredName: e.target.value });
        }}
        value={personalDetails.preferredName}
      />
      <FormFieldSelectGender
        edit={edit}
        label={t("Gender")}
        required
        value={personalDetails.gender}
        error={validation.gender.$error}
        onChange={(option) => onPersonalDetailsUpdated({ gender: option?.value })}
      />

      {showPreferredPronouns && (
        <FormFieldSelect
          id="pronouns"
          edit={edit}
          onChange={(option) => {
            onPersonalDetailsUpdated({ preferredPronouns: option?.value });
          }}
          label={t("Preferred pronouns")}
          placeholder={t("Select pronouns")}
          isSearchable={false}
          options={pronounOptions}
          value={personalDetails.preferredPronouns}
        />
      )}
      <FormFieldDatePicker
        id="date-of-birth"
        edit={edit}
        label={t("Date of Birth")}
        required
        pickerHiddenOnMobile
        selected={getDateIfValid(personalDetails.dob)}
        onChange={(date) => {
          onPersonalDetailsUpdated({
            dob: date ? formatAsISODate(date) : undefined,
          });
        }}
        error={validation.dob.$error}
      />
      <FormFieldUserSsnInput
        id="ssn"
        edit={edit}
        key={edit ? "editable-ssn" : "frozen-ssn"}
        label="SSN"
        userId={patientId}
        ssnLastFour={personalDetails.ssnLastFour}
        value={personalDetails.ssn}
        onSsnError={handleError}
        onValueChange={(value) => {
          onPersonalDetailsUpdated({
            ssn: value,
          });
        }}
      />
      {referralOptions.length > 0 && onReferredByUpdated && (
        <FormFieldSelect
          display="label"
          edit={edit}
          id="referred-By"
          label="Referred By"
          onChange={(option) => {
            onReferredByUpdated(option?.value);
          }}
          options={referralOptions}
          value={referredBy}
        />
      )}
    </>
  );
};
