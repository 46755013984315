import React from "react";
import { useTranslation } from "react-i18next";
import {
  BaseFormFieldSelectGender,
  BaseFormFieldSelectGenderProps,
} from "@libs/components/UI/BaseFormFieldSelectGender";

export const useGenderOptions = () => {
  const { t } = useTranslation();
  const genderOptions = React.useMemo(() => {
    return [
      { value: "MALE" as const, label: t("Male") },
      { value: "FEMALE" as const, label: t("Female") },
      { value: "OTHER" as const, label: t("Other") },
      { value: "UNKNOWN" as const, label: t("Unknown") },
    ];
  }, [t]);

  return genderOptions;
};

export const FormFieldSelectGender: React.FC<Omit<BaseFormFieldSelectGenderProps, "options">> = (props) => {
  const options = useGenderOptions();

  return <BaseFormFieldSelectGender {...props} options={options} />;
};
