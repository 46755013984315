import { useTranslation } from "react-i18next";
import React from "react";
import { PersonalDetailsVO } from "@libs/api/generated-api";
import { useObjectState } from "@libs/hooks/useObjectState";
import { Button } from "@libs/components/UI/Button";
import { RadioList } from "@libs/components/UI/RadioList";
import { Form } from "@libs/components/UI/Form";
import { FormFieldNumberInput } from "@libs/components/UI/FormFieldNumberInput";
import { useGenderOptions } from "components/UI/FormFieldSelectGender";
import { Modal } from "components/UI/Modal";
import { ModalContent } from "components/UI/ModalComponents";

type Props = {
  patientAttributes: PersonalDetailsVO;
  onChangePatientAttributes: (newAttributes: Partial<PersonalDetailsVO>) => void;
};
export const PatientPreviewAdjustAttributesModal: React.FC<Props> = ({
  patientAttributes,
  onChangePatientAttributes,
}) => {
  const genderOptions = useGenderOptions();
  const { t } = useTranslation();
  const [newAttributes, updateNewAttributes] = useObjectState(patientAttributes);
  const handleClose = React.useCallback(
    (didCancel?: boolean) => onChangePatientAttributes(didCancel ? patientAttributes : newAttributes),
    [newAttributes, onChangePatientAttributes, patientAttributes]
  );

  return (
    <Modal onClose={() => handleClose(true)} aria-labelledby="Adjust Patient Attributes">
      <ModalContent>
        <div
          className={`
            flex
            flex-col
            bg-white
            w-full
            p-6
            rounded-lg
            relative
            max-w-md
            gap-3
            overflow-y-visible
            pt-8
            md:pt-5
            min-w-[310px]
          `}
        >
          <div className="text-xl font-sansSemiBold text-secondaryTheme">Preview As</div>
          <Form
            id="patient-attributes"
            className={`
              flex
              flex-col
              bg-white
              w-full
              rounded-lg
              relative
              max-w-md
              gap-3
            `}
            onSubmit={(e) => {
              e.preventDefault();
              handleClose(false);
            }}
          >
            <RadioList
              label={t("Gender")}
              layout="vert"
              selectedValue={newAttributes.gender}
              onChange={(e) => {
                updateNewAttributes({ gender: e.target.value as PersonalDetailsVO["gender"] });
              }}
              options={genderOptions}
            />
            <div className="font-sansSemiBold">{t("Age")}</div>
            <FormFieldNumberInput
              id="age"
              edit
              value={newAttributes.age}
              displayErrorMessage={false}
              error={newAttributes.age ? undefined : "Age required"}
              placeholder="Patient Age"
              onValueChange={(value) => {
                updateNewAttributes({ age: value });
              }}
            />
            <Button className="mt-4" disabled={!newAttributes.age} onClick={() => handleClose(false)}>
              Update
            </Button>
          </Form>
        </div>
      </ModalContent>
    </Modal>
  );
};
