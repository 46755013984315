/* eslint-disable complexity */
import React from "react";
import { useTranslation } from "react-i18next";
import { FormBooleanInputElementVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { PatientFormInputElement } from "components/PatientForms/FormElements/PatientFormInputElement";
import { PatientResponses, ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";
import { PatientFormValidation } from "components/PatientForms/hooks/usePatientFormValidation";
import { YesNoQuestion } from "components/PatientForms/FormElements/YesNoQuestion";
import { PatientFormFieldTitle } from "components/PatientForms/FormElements/FormFieldTitle";

type Props = {
  id: string;
  element: FormBooleanInputElementVO;
  edit: boolean;
  responsesById: PatientResponses;
  onChangeResponse: ResponseChangedCallback;
  validation: PatientFormValidation;
  error?: string;
};

const cxStyles = {
  name: (editing: boolean) => cx("text-base text-greyDark", !editing && "font-sansSemiBold"),
  content: "flex flex-col gap-2 text-sm",
};

export const PatientFormBooleanElement: React.FC<Props> = ({
  element,
  edit,
  responsesById,
  onChangeResponse,
  validation,
  error,
}) => {
  const { t } = useTranslation();

  const { uuid, settings, conditionalElement, title } = element;
  const userResponse = responsesById[uuid];
  const required = settings.includes("REQUIRED");

  if (userResponse && userResponse.type !== "BOOLEAN") {
    //Should we throw an error?
    return null;
  }

  const isOn = Boolean(userResponse?.response);
  const radioValue = userResponse?.response === undefined ? undefined : isOn ? true : false;

  return (
    <div>
      <div className={cxStyles.content}>
        {edit ? (
          <YesNoQuestion
            label={title}
            required={required}
            error={validation[uuid]?.error}
            selectedValue={radioValue}
            onChange={(value) => {
              onChangeResponse(uuid, {
                type: "BOOLEAN",
                response: value,
              });

              if (conditionalElement) {
                const conditionalElementResponse = responsesById[conditionalElement.uuid];

                if (!value && conditionalElementResponse) {
                  const newResponse = { ...conditionalElementResponse };

                  switch (newResponse.type) {
                    case "DATE":
                    case "STRING": {
                      newResponse.response = "";

                      break;
                    }
                    case "NUMBER":
                    case "BOOLEAN": {
                      newResponse.response = undefined;

                      break;
                    }
                    case "SELECT": {
                      responsesById[conditionalElement.uuid] = {
                        type: "SELECT",
                        responses: {},
                      };

                      break;
                    }
                    // No default
                  }

                  onChangeResponse(conditionalElement.uuid, { ...newResponse });
                }
              }
            }}
          />
        ) : (
          <>
            <PatientFormFieldTitle title={title} required={required} error={error} />
            <div className={cxFormFieldStyle.controlValueOnly}>
              {isDefined(radioValue) ? (radioValue ? t("Yes") : t("No")) : t("Not Provided")}
            </div>
          </>
        )}
        {isOn && conditionalElement ? (
          <PatientFormInputElement
            element={conditionalElement}
            edit={edit}
            validation={validation}
            responsesById={responsesById}
            onChangeResponse={onChangeResponse}
          />
        ) : null}
      </div>
    </div>
  );
};
