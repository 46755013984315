import { FC, MouseEventHandler, useCallback, useRef } from "react";
import { format } from "date-fns";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { isDefined } from "@libs/utils/types";
import { DatePickerSelectMenuHeader } from "@libs/components/UI/DatePickerSelectMenuHeader";
import { FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as CalendarIcon } from "@libs/assets/icons/calendar.svg";
import { FormattedDateInput } from "@libs/components/UI/FormattedDateInput";
import { IS_MOBILE_DEVICE } from "utils/userAgent";
import { DatePickerBase } from "components/UI/DatePickerBase";

type FormFieldDatepickerProps = FormFieldProps & ReactDatePickerProps;

export interface FormFieldSelectMenusDatepickerProps extends Omit<FormFieldDatepickerProps, "value"> {
  formatReadOnlyValue?: (date?: Date | null) => string | JSX.Element;
  Icon?: IconComponent;
  onClickIcon?: MouseEventHandler<HTMLButtonElement>;
  iconTooltip?: Omit<FloatingTooltipProps, "children">;
  pickerHiddenOnMobile?: boolean;
}

const MIN_YEAR = 1900;
const MIN_DATE = new Date(MIN_YEAR, 0, 1);
const MAX_DATE = new Date("12/31/2099");

// eslint-disable-next-line complexity
export const FormFieldDatePicker: FC<Omit<FormFieldSelectMenusDatepickerProps, "portalId">> = ({
  disabled,
  required,
  label,
  error,
  isClearable,
  className,
  children,
  id,
  edit = true,
  formatReadOnlyValue,
  maxDate,
  displayErrorMessage = true,
  Icon,
  onClickIcon,
  iconTooltip,
  pickerHiddenOnMobile,
  onChange,
  ...datePickerOptions
}) => {
  const canOpenDatepicker = !(pickerHiddenOnMobile && IS_MOBILE_DEVICE);

  const fieldId = useEnsureId({ customId: id });

  const datePickerRef = useRef<DatePicker>(null);

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Tab") {
      datePickerRef.current?.setOpen(false);
    }
  }, []);

  return (
    <FormField
      disabled={disabled}
      required={required}
      label={label}
      error={error}
      edit={edit}
      className={cx(className)}
      displayErrorMessage={displayErrorMessage}
      id={fieldId}
    >
      <div className={cxFormFieldStyle.wrapper}>
        {edit ? (
          <>
            <DatePickerBase
              id={id}
              className={cx(
                cxFormFieldStyle.control({
                  hasIcon: true,
                }),
                cxFormFieldStyle.input
              )}
              calendarStartDay={0}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              yearDropdownItemNumber={4}
              scrollableYearDropdown
              placeholderText="MM/DD/YYYY"
              minDate={MIN_DATE}
              maxDate={maxDate || MAX_DATE}
              onChange={onChange}
              {...datePickerOptions}
              {...(canOpenDatepicker ? undefined : { open: false })}
              customInput={<FormattedDateInput />}
              disabled={disabled}
              ref={datePickerRef}
              onKeyDown={onKeyDown}
              renderCustomHeader={(params) => (
                <DatePickerSelectMenuHeader
                  minDate={MIN_DATE}
                  maxDate={maxDate || MAX_DATE}
                  params={params}
                />
              )}
            />
            {isClearable && isDefined(datePickerOptions.selected) && (
              <ButtonIcon
                className="absolute right-8 bottom-1 z-10"
                SvgIcon={CancelIcon}
                disabled={disabled}
                size="sm"
                theme="primary"
                onClick={() => onChange(null, undefined)}
              />
            )}
            {canOpenDatepicker && (
              <div
                className={cxFormFieldStyle.iconContainer({
                  clickable: Boolean(onClickIcon),
                })}
              >
                {onClickIcon ? (
                  <ButtonIcon
                    SvgIcon={Icon ?? CalendarIcon}
                    onClick={onClickIcon}
                    tooltip={iconTooltip}
                    disabled={disabled}
                    theme="primary"
                    size="sm"
                  />
                ) : Icon ? (
                  <Icon className={cxFormFieldStyle.icon({ disabled })} />
                ) : (
                  <CalendarIcon className={cxFormFieldStyle.icon({ disabled })} />
                )}
              </div>
            )}

            {children}
          </>
        ) : (
          <span className={cxFormFieldStyle.controlValueOnly}>
            {formatReadOnlyValue
              ? formatReadOnlyValue(datePickerOptions.selected)
              : datePickerOptions.selected
                ? `${format(datePickerOptions.selected, "MM/dd/yyyy")}`
                : "-"}
          </span>
        )}
      </div>
    </FormField>
  );
};
